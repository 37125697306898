@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes breakApart {
    0% {
        transform: rotate(0) translateY(0);
        opacity: 1;
    }
    20% {
        transform: rotate(10deg) translateY(-10px);
    }
    40% {
        transform: rotate(-10deg) translateY(10px);
    }
    60% {
        transform: rotate(20deg) translateY(-20px);
        opacity: 0.9;
    }
    80% {
        transform: rotate(-20deg) translateY(20px);
        opacity: 0.5;
    }
    100% {
        transform: rotate(30deg) translateY(100vh);
        opacity: 0;
    }
}

/* Style for gallery divs */
.card-item {
    transition: transform 1s, opacity 1s;
    animation-delay: var(--animation-delay);
}

/* When breaking apart */
.card-item-break {
    animation: breakApart 1.5s forwards;
}

/* Full-page canvas background */
.canvas-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none; /* Hidden initially */
    z-index: -1;
}

/* Visible state for canvas background */
.canvas-visible {
    display: block;
}
